<template>
  <div class="error-wrap">
    <div class="error-img"><img src="@/assets/images/acryl-empty-sawtooth.svg" alt=""></div>
    <div class="error-text">
      <h1><img src="@/assets/images/logo-full.png" alt=""></h1>
      <h2 class="title"><p v-html="$t('error.400body1')"></p></h2>
      <div class="text-info"><p v-html="$t('error.400body2')"></p></div>

      <a class="button-home" @click="handleLink('main')">{{ $t('common.go.acryl') }} <img src="@/assets/images/arrow.svg" alt=""></a>
    </div>
  </div>
</template>

<script>
import { handleLink } from '@/assets/js/util.js'

export default {
	name: 'ErrorPage',
	methods: {
		handleLink (path) { handleLink(this.$router, path) }
	}
}
</script>

<style>
		.error-wrap{ width:1200px; position: absolute; top:50%; left:50%; transform:translate(-50%, -50%);  display: flex; flex-direction: row; flex-flow: row nowrap; flex-wrap: nowrap; justify-content: center; align-items:center}
		.error-wrap .error-img{ width: 50% }
		.error-wrap .error-text h1 img{width:156px }
		.error-wrap .error-text {width:50%}
		.error-wrap .error-text .title{ font-size:56px; line-height: 72px; color:#111; margin-top: 36px}
		.error-wrap .error-text .text-info{ font-size:24px; line-height: 40px; font-weight: 300; color:#111; margin-top: 24px}
		.error-wrap .error-text .button-home{ min-width: 358px; height: 72px; line-height: 72px; border-radius: 250px; background-color: #fff400;; font-size:24px; color:#111; font-weight: bold; display: inline-block; margin-top: 80px; text-align: center}
		.error-wrap .error-text .button-home img{ margin-top: -5px; vertical-align: middle;}
		
		@media screen and (max-width: 1200px){
			.error-wrap{ width:100%; }
			.error-wrap .error-img img{ width:38.33vw }
			.error-wrap .error-text h1 img{width:8.75vw}
			.error-wrap .error-text .title{ font-size:4.67vw; line-height: 6vw;}
			.error-wrap .error-text .text-info{ font-size:2vw; line-height: 3.33vw; margin-top: 2vw}
			.error-wrap .error-text .button-home{ min-width: 29.83vw; height: 6vw; line-height: 6vw; font-size:2vw; margin-top:6.67vw; }
			.error-wrap .error-text .button-home img{ width: 3vw; }
		}
		@media screen and (max-width: 768px){
			.error-wrap .error-img { text-align: center}
			.error-wrap .error-img img{ width:33.33vw }
			.error-wrap .error-text h1 img{width:8.75vw}
			.error-wrap .error-text .title{ font-size:3.65vw; line-height: 5.21vw; margin-top:3.39vw }
			.error-wrap .error-text .text-info{ font-size:1.82vw; line-height: 2.86vw; margin-top: 1.82vw}
			.error-wrap .error-text .button-home{ min-width: 29.95vw; height: 6.51vw; line-height: 6.51vw; font-size:1.82vw; margin-top:6.51vw; }
			.error-wrap .error-text .button-home img{ width: 3vw; }
		}
		@media screen and (max-width: 640px){
			.error-wrap{ width:100%; display: block}
			.error-wrap .error-img { width:100%}
			.error-wrap .error-img img{ width:45.56vw }
			.error-wrap .error-text {width:100%; text-align: center}
			.error-wrap .error-text h1 img{width:17.78vw; margin-top: 16.67vw}
			.error-wrap .error-text .title{ font-size:5.56vw; line-height: 7.78vw; margin-top:5vw }
			.error-wrap .error-text .text-info{ font-size:3.33vw; line-height: normal; margin-top: 3.89vw}
			.error-wrap .error-text .button-home{ min-width: 63.89vw; height: 13.89vw; line-height:13.89vw; font-size:3.89vw; margin-top:5.56vw; }
			.error-wrap .error-text .button-home img{ width: 5.56vw; }
		}
	</style>